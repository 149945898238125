* {
  margin: 0;
}

body {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px;
}
