.header {
    background-color: #0033cc;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
}

.header__logo {
    object-fit: scale-down;
    width: 150px;
    margin-left: 10px;
    margin-right: 1px;
    margin-top: 0px;
}

.header__optionInfo{
    margin-top: 40px;
    margin-bottom: 10px;
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    align-items: left;
    //align-items: center;
}

.header__optionBasket {
    margin-left: 20px;
    margin-right: 70px;
    display: flex;
    align-items: center;
}

.header__basketCount {
    margin-left: 10px;
    margin-right: 10px;
}
.header__nav {
    margin-top: 20px;
    display: flex;
    justify-content: left;
    //justify-content: space-around;
}

.header__option {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
}

.header__optionLine1{
    font-size: 18px;
    font-weight: 800;
}

.header__optionLine2 {
    font-size: 18px;
    font-weight: 800;
}

.header__link {
    color: white;
    font-size: 20px;
    text-decoration: none;
    margin-top: 0px;
}

.header__search {
    display: flex;
    flex: 1;
}

.header__searchInput {
    height: 12px;
    padding: 10px;
    border: none;
    width: 100%;
}

.header__searchIcon {
    background-color: orange;
    padding: 5px;
    height: 22px !important;
}

.header__button {
    height:30px;
		font-size: 24px;
}
