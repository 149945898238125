.home {
    max-width: 100%;
    //margin-left: auto;
    //margin-right: auto;
}

.home__row {
    display: flex;
    z-index: 1;
    //margin-left: 5px;
    //margin-right: 5px;
}

.header__image {
    mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0) );
    z-index: -1;
    margin-bottom: -150px;
}


body {
  font-family: "Lato", sans-serif;
}

.grid-container {
  display: grid;
  grid-template-columns: 20% 80%;
  grid-auto-rows: minmax(10px, auto);
	border: 9px solid #dfdfdf;
}

.header {
	grid-column-start: 1;
	grid-column-end: 3;
	grid-row-start: 1;
	grid-row-start: 2;
  z-index: 2;
  top: 0;
  left: 0;
  background-color:   #0033cc;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 2px;
  padding-bottom: 0px;
	//border: solid 4px;
  font-size: 25px;
  color: rgb(255, 255, 255);
	text-align: center;
}

.logo {
	grid-column-start: 2;
	grid-column-end: 3;
	grid-row-start: 3;
	grid-row-end: 4;
  z-index: 2;
	display: block;
	//border: solid 0px;
}

.sidenav {
	grid-column-start: 1;
	grid-column-end: 2;
	grid-row-start: 3;
	grid-row-end: 4;
  z-index: 1;
  top: 10;
  left: 0;
  background-color: rgb(22, 34, 192);
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  padding-bottom: -60px;
	//border: solid 4px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: rgb(255, 255, 255);
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}


@media screen and (max-height: 450px) {
  .sidenav {padding-top: 15px;}
  .sidenav a {font-size: 18px;}
}
