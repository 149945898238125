ul {
  display: flex;
  flex-wrap: wrap;
	//align-content: left;
	padding: 10px;
	padding-left: 20px;
	padding-top: 0px;
  list-style-type: none;
	align-content: flex-end;
}

li {
  //height: 40vh;
  flex-grow: 1;
}

img {
  max-height: 100%;
  min-width: 100%;
  object-fit: cover;
  vertical-align: bottom;
}

