.subtotal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    //width: 800px;
    height: 60px;
    padding: 20px;
    background-color: #ffffff;
    //background-color: #f3f3f3;
    border: 0px solid #dddddd;
    border-radius: 3px;

}

.subtotal-checkout {
    display: flex;
    flex-direction: column;
    //justify-content: space-between;
    justify-content: left;
    //width: 800px;
    height: 60px;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 3px;

}
