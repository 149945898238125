.footer {
		background-color: rgb(22, 34, 192);
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 100;
		font-size: 20px;
		color: rgb(255, 255, 255);
}

.footer__nav {
    display: flex;
    justify-content: space-evenly;
}

.footer__option {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
}

.footer__optionLine1{
    font-size: 10px;
}

.footer__optionLine2 {
    font-size: 13px;
    font-weight: 800;
}

.footer__link {
    color: white;
    text-decoration: none;
}

.old {
		background-color: rgb(22, 34, 192);
}
